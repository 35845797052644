import Colleges from "../pages/Colleges/Colleges";
import CourseColleges from "../pages/Colleges/CourseColleges";
import PlaceCourseColleges from "../pages/Colleges/PlaceCourseColleges";
import ComingSoon from "../pages/Main/ComingSoon";


const collegesRoutes = [
  { path: "/colleges", element: <Colleges /> },
  { path: "/colleges/:course", element: <CourseColleges /> },
  { path: "/colleges/:course/:place", element: <PlaceCourseColleges /> },
];


export default collegesRoutes;