import React from "react";
import "./PlaceFilter.css"; // Assuming you have styles for these filters

const PlaceFilter = ({ courses, places, selectedFilters, onFilterChange }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    onFilterChange({ ...selectedFilters, [name]: value });
  };

  // Normalize places for comparison
  const normalizeString = (str) => str.toLowerCase().replace(/\s+/g, "-");

  return (
    <div className="filters">
      {/* Dropdown for selecting courses */}
      <select
        name="course"
        value={selectedFilters.course}
        onChange={handleChange}
      >
        {courses.map((course, index) => (
          <option key={index} value={course}>
            {course.charAt(0).toUpperCase() + course.slice(1)}
          </option>
        ))}
      </select>

      {/* Dropdown for selecting places */}
      <select
        name="place"
        value={selectedFilters.place}
        onChange={handleChange}
      >
        <option value="">Select a Place</option> {/* Default placeholder */}
        {places.map((place, index) => (
          <option key={index} value={normalizeString(place)}>
            {place}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PlaceFilter;
