import * as React from "react";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";

// Utility to parse the source and extract the necessary information
const parseSource = (source) => {
  if (!source) return {};

  const parser = new DOMParser();
  const doc = parser.parseFromString(source, "text/html");
  const anchor = doc.querySelector("a");
  const img = doc.querySelector("img");

  if (!anchor || !img) return {};

  const title = anchor.getAttribute("title");
  const titleParts = title?.split(", ") || [];
  const titlePart = titleParts[0];
  const licenseMatch = title.match(/CC BY(?:-[A-Z]+)? [\d\.]+/);
  const licenseText = licenseMatch ? licenseMatch[0] : null;
  const licenseLinkMatch = title.match(
    /https:\/\/creativecommons\.org\/licenses\/[a-z\-]+\/[\d\.]+/
  );
  const licenseLink = licenseLinkMatch ? licenseLinkMatch[0] : null;
  const platformPart = titleParts[titleParts.length - 1];
  const imageSrc = img.getAttribute("src");
  const altText = img.getAttribute("alt");

  return {
    titlePart,
    licenseText,
    licenseLink,
    platformPart,
    imageSrc,
    altText,
  };
};

// Dark theme configuration
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      paper: "#1d1d1d",
    },
    text: {
      primary: "#ffffff",
      secondary: "#b0b0b0",
    },
  },
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CollegeCard({ name, source, description, rank, photo }) {
  const [expanded, setExpanded] = React.useState(false);
  const [showAttribution, setShowAttribution] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const toggleAttribution = () => {
    setShowAttribution(!showAttribution);
  };

  // Fallback image URL if photo is not provided
  const defaultImage =
    "https://images.unsplash.com/photo-1448584109583-8f5fe2e61544?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTR8fHVuaXZlcnNpdHl8ZW58MHx8MHx8fDA%3D";

  // Parsing the source data
  const {
    titlePart,
    licenseText,
    licenseLink,
    platformPart,
    imageSrc,
    altText,
  } = parseSource(source);

  return (
    <ThemeProvider theme={darkTheme}>
      <Card sx={{ maxWidth: 345, backgroundColor: "background.paper" , borderRadius: 3}}>
        <CardHeader
          avatar={<Avatar sx={{ bgcolor: "white" }} aria-label="recipe">{rank}</Avatar>}
          // action={<IconButton aria-label="settings"><MoreVertIcon /></IconButton>}
          title={name}
          // subheader={`Rank: ${rank}`}
          sx={{ color: "text.primary" }}
        />
        <div style={{ position: "relative" }}>
          {!showAttribution ? (
            <>
              <CardMedia
                component="img"
                height="194"
                image={imageSrc || photo || defaultImage}
                alt={altText || "Default Image"}
              />
              <IconButton
                aria-label="info"
                onClick={toggleAttribution}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  },
                }}
              >
                <InfoIcon />
              </IconButton>
            </>
          ) : (
            <div
              style={{
                height: "194px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "background.default",
                position: "relative",
              }}
            >
              <Typography sx={{ color: "text.primary", textAlign: "center" }}>
                {titlePart || "Attribution Not Available"} - {platformPart || "Platform Not Available"}
                {licenseText && (
                  <Typography>
                    License:{" "}
                    <a href={licenseLink} target="_blank" rel="noopener noreferrer">
                      {licenseText}
                    </a>
                  </Typography>
                )}
              </Typography>
              <IconButton
                aria-label="back"
                onClick={toggleAttribution}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  },
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </div>
          )}
        </div>
        {/* <CardContent>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {description}
            {name}
            {`Rank: ${rank}`}
          </Typography>
        </CardContent> */}
        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites" sx={{ color: "text.primary" }}>
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="share" sx={{ color: "text.primary" }}>
            <ShareIcon />
          </IconButton>
          {/* <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon sx={{ color: "text.primary" }} />
          </ExpandMore> */}
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography sx={{ marginBottom: 2, color: "text.primary" }}>
              Source: {titlePart || "Attribution Not Available"}
            </Typography>
            {licenseLink && licenseText && (
              <Typography>
                License:{" "}
                <a href={licenseLink} target="_blank" rel="noopener noreferrer">
                  {licenseText}
                </a>
              </Typography>
            )}
            {platformPart && <Typography>{platformPart}</Typography>}
          </CardContent>
        </Collapse>
      </Card>
    </ThemeProvider>
  );
}
