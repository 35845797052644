// import React from "react";
// import "./HomeContent.css";
// import { Link } from "react-router-dom";
// const HomeContent = ({ children }) => {
//   return (
//     <div className="hero">
//       <div className="mask">
//         <img className="into-img" src="https://images.unsplash.com/photo-1564057740580-02521995d221?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="IntroImg" />
//       </div>
//       <div className="content">
//         <p>Discover the ultimate Top 10 lists across every category, from Anime Characters to Tech Innovations and Sports Legends!</p>
//         <h1>theTopten.in</h1>
//         <div>
//           <Link to="/colleges" className="btn">
//             Colleges
//           </Link>
//           <Link to="/contact" className="btn btn-light">
//             Contact
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeContent;

import React from "react";
import "./BannerContent.css";
import { Link } from "react-router-dom";

const BannerContent = ({ title, description, imageSrc, buttons }) => {
  return (
    <div className="hero">
      <div className="mask">
        <img className="into-img" src={imageSrc} alt="IntroImg" />
      </div>
      <div className="content">
        <p>{description}</p>
        <h1>{title}</h1>
        <div>
          {buttons &&
            buttons.map((btn, index) => (
              <Link
                to={btn.link}
                key={index}
                className={`btn ${btn.style || ""}`}
              >
                {btn.text}
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default BannerContent;
