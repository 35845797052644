import "./ImageHeader.css";
import React from "react";

const ImageHeader = ({ heading, text, backgroundImage }) => {
  // Default image URL
  const defaultImage =
    "https://images.unsplash.com/photo-1635865597298-611506487808?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  // Dynamic inline style with fallback to default image
  const heroStyle = {
    "--bg-image": `url(${backgroundImage || defaultImage})`,
  };

  return (
    <div className="hero-img" style={heroStyle}>
      <div className="heading">
        <h1>{heading}</h1>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default ImageHeader;
