import React from "react";
import { Outlet } from "react-router-dom";
import Footer from './Footer';
import Navbar from './Navbar';
import './Layout.css';

const Layout = () => {
  return (
    <>
      <Navbar />
      <main>
        <Outlet /> {/* Dynamically renders the current route's content */}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
