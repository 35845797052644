import React from "react";
import BannerContent from "../../components/Main/BannerContent";

const Home = () => {
  const buttons = [
    { text: "Colleges", link: "/colleges", style: "" },
    { text: "Contact", link: "/contact", style: "btn-light" },
  ];

  return (
    <div>
      <BannerContent
        title="theTopten.in"
        description="Discover the ultimate Top 10 lists across every category, from Anime Characters to Tech Innovations and Sports Legends!"
        imageSrc="https://images.unsplash.com/photo-1564057740580-02521995d221?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        buttons={buttons}
      />
    </div>
  );
};
export default Home;
