import { useEffect } from 'react';
import { useLoader } from '../../context/LoaderContext';
import nprogress from 'nprogress';
import 'nprogress/nprogress.css'; // Import default styles

nprogress.configure({ showSpinner: false, speed: 400, trickleSpeed: 200 });

function PageLoader() {
  const { loading } = useLoader();

  useEffect(() => {
    if (loading) {
      nprogress.start();
    } else {
      nprogress.done();
    }
  }, [loading]);

  return null; // This component only controls the loader, no UI
}

export default PageLoader;
