import CategoryFacts from "../pages/Facts/CategoryFacts";
import Facts from "../pages/Facts/Facts";
import PageslugCategoryFacts from "../pages/Facts/PageslugCategoryFacts";
import ComingSoon from "../pages/Main/ComingSoon";

const factsRoutes = [
  // { path: "/facts", element: <Facts /> },
  // { path: "/facts/:category", element: <CategoryFacts /> },
  // { path: "/facts/:category/:pageslug", element: <PageslugCategoryFacts /> },
  { path: "/facts", element: <ComingSoon /> },
  { path: "/facts/:category", element: <ComingSoon /> },
  { path: "/facts/:category/:pageslug", element: <ComingSoon /> },
];

export default factsRoutes;
