import "./Footer.css";
import React from "react";
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaFacebook,
  FaHome,
  FaLinkedin,
  FaMailBulk,
  FaPhone,
  FaInstagram,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="listscontainer">
        <div className="list1">
          <ul>
            <li>
            <Link to="/about">About</Link>
            </li>
            <li>
            <Link to="/advertisewithus">Advertise With Us</Link>
            </li>
          </ul>
        </div>
        <div className="list2">
          <ul>
          <li>
            <Link to="/contact">Contact</Link>
            </li>
            <li>
            <Link to="/privacypolicy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div className="list3">
          <ul>
          <li>
            <Link to="/careers">Careers</Link>
            </li>
            <li>
            <Link to="/termsandconditions">Terms and Conditions</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="list4">
        <div className="social-media">
          <FaFacebook
            size={30}
            style={{ color: "white", marginRight: "1rem" }}
          />
          <FaTwitter
            size={30}
            style={{ color: "white", marginRight: "1rem" }}
          />
          <FaLinkedin
            size={30}
            style={{ color: "white", marginRight: "1rem" }}
          />
          <FaInstagram
            size={30}
            style={{ color: "white", marginRight: "1rem" }}
          />
        </div>
        <div className="website-copyrights">
          &copy; {new Date().getFullYear()} TheTopTen.in All Rights Reserved.
        </div>
      </div>
    </div>
  );
};
export default Footer;