// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { Grid } from "@mui/material"; // Importing the standard Grid component instead of Grid2
// import HeroImg1 from "../../components/Main/HeroImg1";
// import { mainindex } from "../../data/mainindex";
// import RecipeReviewCard from "../../components/Material/CourseCard";
// import styles from "./Colleges.module.css";

// const DEFAULT_IMAGE_URL =
//   "https://images.unsplash.com/photo-1736135290115-ec129b0f31fc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwxNHx8fGVufDB8fHx8fA%3D%3D";

// const darkTheme = createTheme({
//   palette: {
//     mode: "dark",
//     background: {
//       default: "#121212",
//       paper: "#1d1d1d",
//     },
//     text: {
//       primary: "#ffffff",
//       secondary: "#b0b0b0",
//     },
//   },
// });

// const Colleges = () => {
//   const { category } = useParams();
//   const [pages, setPages] = useState(null);
//   const [error, setError] = useState("");

//   useEffect(() => {
//     const loadPages = async () => {
//       try {
//         const categoryModule = await import(`../../data/categories/colleges/updatedcollegesIndex2`);
//         const categoryPages = categoryModule?.updatedcollegesIndex2 || [];
//         if (categoryPages.length === 0) {
//           throw new Error("No pages found in the selected category.");
//         }
//         setPages(categoryPages);
//       } catch (err) {
//         setError("Error loading pages for this category. Please try again later.");
//         console.error(err);
//       }
//     };

//     loadPages();
//   }, [category]);

//   const categoryData = mainindex.find((cat) => cat.slug === "colleges");

//   if (!categoryData) {
//     return <h1>Category Not Found</h1>;
//   }

//   if (error) {
//     return <h1>{error}</h1>;
//   }

//   if (!pages) {
//     return <h1>Loading...</h1>;
//   }

//   return (
//     <ThemeProvider theme={darkTheme}>
//       <div className={styles["category-page"]}>
//         <HeroImg1
//           heading={categoryData.name}
//           text={categoryData.description}
//           backgroundImage="https://images.unsplash.com/photo-1599634874901-e919c4fe1400?q=80&w=1489&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
//         />
//         <div className={styles["project-container"]}>
//           <Grid container spacing={3}>
//             {pages.map((page) => (
//               <Grid item xs={12} sm={6} md={4} lg={3} key={page.slugNumber}>
//                 <RecipeReviewCard
//                   title={page.title}
//                   subheader={`Description: ${page.description || "N/A"}`}
//                   image={page.image || DEFAULT_IMAGE_URL}
//                   attribution={page.attribution || "Attribution not available"}
//                   description={page.description || "No description available."}
//                   method={page.method || []} // Pass method if available
//                 />
//               </Grid>
//             ))}
//           </Grid>
//         </div>
//       </div>
//     </ThemeProvider>
//   );
// };

// export default Colleges;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Grid } from "@mui/material"; // Using the standard Grid component
import { mainindex } from "../../data/mainindex";
import CourseCard from "../../components/Colleges/CourseCard";
import styles from "./Colleges.module.css";
import ImageHeader from "../../components/Main/ImageHeader";

const DEFAULT_IMAGE_URL =
  "https://images.unsplash.com/photo-1736135290115-ec129b0f31fc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwxNHx8fGVufDB8fHx8fA%3D%3D";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      paper: "#1d1d1d",
    },
    text: {
      primary: "#ffffff",
      secondary: "#b0b0b0",
    },
  },
});

const Colleges = () => {
  const { course, place } = useParams(); // Updated to use new route parameters
  const [pages, setPages] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const loadPages = async () => {
      try {
        const categoryModule = await import(
          `../../data/Colleges/updatedCollegesIndex2`
        );
        const categoryPages = categoryModule?.updatedcollegesIndex2 || [];
        const filteredPages = categoryPages.filter((page) => {
          const matchesCourse = course
            ? page.category.toLowerCase() === course.toLowerCase()
            : true;
          const matchesPlace = place
            ? page.city.toLowerCase() === place.toLowerCase() ||
              page.state.toLowerCase() === place.toLowerCase()
            : true;
          return matchesCourse && matchesPlace;
        });

        if (filteredPages.length === 0) {
          throw new Error("No pages found for the selected filters.");
        }
        console.log(filteredPages);
        setPages(filteredPages);
      } catch (err) {
        setError(
          "Error loading pages for this category. Please try again later."
        );
        console.error(err);
      }
    };

    loadPages();
  }, [course, place]);

  const categoryData = mainindex.find((cat) => cat.slug === "colleges");

  if (!categoryData) {
    return <h1>Category Not Found</h1>;
  }

  if (error) {
    return <h1>{error}</h1>;
  }

  if (!pages) {
    return <h1>Loading...</h1>;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <div className={styles["category-page"]}>
        <ImageHeader
          heading={categoryData.name}
          text={categoryData.description}
          backgroundImage="https://images.unsplash.com/photo-1599634874901-e919c4fe1400?q=80&w=1489&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />
        <div className={styles["project-container"]}>
          <Grid container spacing={3}>
            {pages.map((page) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={page.slugNumber}>
                <CourseCard
                  title={page.slug}
                  subheader={`Description: ${page.description || "N/A"}`}
                  image={page.image || DEFAULT_IMAGE_URL}
                  attribution={page.attribution || "Attribution not available"}
                  description={page.description || "No description available."}
                  method={page.method || []} // Pass method if available
                  courseslug={page.slug || "Slug not available"}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Colleges;
