import React from "react";
import ImageHeader from "../../components/Main/ImageHeader";
import ContentImageSplit from './../../components/Main/ContentImageSplit';
const About = () => {
  const images = [
    {
      src: "https://images.unsplash.com/photo-1455849318743-b2233052fcff?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      alt: "Image 1"
    },
    {
      src: "https://images.unsplash.com/photo-1482440308425-276ad0f28b19?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      alt: "Image 2"
    }
  ];

  return (
    <>
      <ImageHeader
        heading="About Us."
        text="We deliver well-researched content with descriptions, images, and sources to ensure reliable insights."
        backgroundImage="https://images.unsplash.com/photo-1699993131854-a1cde51ce9da?q=80&w=1462&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
    <ContentImageSplit
      title="Embark on This Adventure with Us"
      description="Join us as we uncover the top contenders in every category and celebrate the best of everything. Thank you for visiting TheTopTen!"
      buttonText="Contact"
      buttonLink="/contact"
      images={images}
    />
    </>
  );
};
export default About;
