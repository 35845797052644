import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ImageHeader from "../../components/Main/ImageHeader";
import styles from "./PageNotFound.module.css"; // Import CSS module

const PageNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "404 - Page Not Found"; // Ensures correct title
    window.history.replaceState({}, "", "/404"); // Helps search engines recognize the error
  }, []);

  return (
    <>
      <Helmet>
        <title>404 - Page Not Found</title>
        <meta name="robots" content="noindex, follow" />
        <meta name="description" content="Oops! The page you are looking for doesn't exist." />
      </Helmet>

      <ImageHeader
        heading="Oops.."
        text="Recheck the URL or return to the homepage."
        backgroundImage="https://images.unsplash.com/photo-1516345079912-c3e011a5a848?q=80&w=1485&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />

      <div className={styles.pageNotFound}>
        <h1>404 - Page Not Found</h1>
        <p>Oops! The page you're looking for doesn't exist or may have been moved.</p>

        <button className={styles.homeButton} onClick={() => navigate("/")}>
          Go Home
        </button>
      </div>
    </>
  );
};

export default PageNotFound;
