import About from "../pages/Main/About";
import ComingSoon from "../pages/Main/ComingSoon";
import Contact from "../pages/Main/Contact";
import Home from "../pages/Main/Home";
import PageNotFound from "../pages/Main/PageNotFound";

const mainRoutes = [
  { path: "/", element: <Home /> },
  { path: "/about", element: <About /> },
  { path: "/contact", element: <Contact /> },
  { path: "/comingsoon", element: <ComingSoon /> },
  { path: "*", element: <PageNotFound /> },
];

export default mainRoutes;
