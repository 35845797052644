export const sportsIndex = [
  {
    "slugNumber": 1,
    "title": "Top Indian Cricketers with Most 100's in all formats",
    "slug": "cricket-most-centuries-india",
    "description": "List of Top Indian Cricketers with Most 100's in all formats"
  },
  {
    "slugNumber": 2,
    "title": "Top F1 Drivers of 2024 ",
    "slug": "top-f1-drivers-2024",
    "description": "List of Top F1 Drivers in 2024"
  }
];