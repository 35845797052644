import React from "react";
import Form from "../../components/Main/Form"
import ImageHeader from './../../components/Main/ImageHeader';

const Contact = () => {
    return <div>
        <ImageHeader heading="Get in Touch." text="Feel free to contact us for any inquiries or collaborations!" 
        backgroundImage="https://images.unsplash.com/photo-1634500956474-9ebdb17e579d?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"/>
        <Form />
    </div>
}
export default Contact;