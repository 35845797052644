import React from "react";
import ImageHeader from "../../components/Main/ImageHeader";
import { FaHeart , FaHeartbeat } from "react-icons/fa";
import styles from "./ComingSoon.module.css"; 

const ComingSoon = () => {
  return (
    <>
      <ImageHeader
        heading={<FaHeartbeat size={50} style={{ color: "#ff6699" }} /> }
        text="We’re crafting something you’ll love."
        backgroundImage="https://images.unsplash.com/photo-1511207538754-e8555f2bc187?q=80&w=1527&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
      <div className={styles["comingSoon"]}>
        <h1>We are working on it.</h1>
        <p>Coming Soon...</p>
      </div>
    </>
  );
};
export default ComingSoon;
