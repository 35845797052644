import * as React from "react";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import WorkIcon from "@mui/icons-material/Work";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Avatar from "@mui/material/Avatar";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      paper: "#1d1d1d",
    },
    text: {
      primary: "#ffffff",
      secondary: "#b0b0b0",
    },
  },
});

export default function JobCard({ title, company, location, salary, logo, description }) {
  const defaultImage = "https://images.unsplash.com/photo-1521791136064-7986c2920216?w=500&auto=format&fit=crop&q=60";

  return (
    <ThemeProvider theme={darkTheme}>
      <Card sx={{ maxWidth: 345, backgroundColor: "background.paper", borderRadius: 3 }}>
        <CardHeader
          avatar={<Avatar sx={{ bgcolor: "white" }} aria-label="company">{company? company.charAt(0): "Boring"}</Avatar>}
          title={title}
          subheader={company}
          sx={{ color: "text.primary" }}
        />
        <CardMedia
          component="img"
          height="194"
          image={logo || defaultImage}
          alt={company}
        />
        <CardContent>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            <WorkIcon sx={{ verticalAlign: "middle" }} /> {title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            <LocationOnIcon sx={{ verticalAlign: "middle" }} /> {location}
          </Typography>
          {salary && (
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              <AttachMoneyIcon sx={{ verticalAlign: "middle" }} /> {salary}
            </Typography>
          )}
          <Typography variant="body2" sx={{ color: "text.secondary", marginTop: 1 }}>
            {description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites" sx={{ color: "text.primary" }}>
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="share" sx={{ color: "text.primary" }}>
            <ShareIcon />
          </IconButton>
        </CardActions>
      </Card>
    </ThemeProvider>
  );
}
