import { Link } from "react-router-dom";
import "./ContentImageSplit.css";
import React from "react";

const ContentImageSplit = ({ title, description, buttonText, buttonLink, images }) => {
  return (
    <div className="content-image-split">
      <div className="left">
        <h1>{title}</h1>
        <p>{description}</p>
        {buttonText && buttonLink && (
          <Link to={buttonLink}>
            <button className="btn">{buttonText}</button>
          </Link>
        )}
      </div>
      <div className="right">
        <div className="img-container">
          {images && images.length > 0 && (
            images.map((image, index) => (
              <div key={index} className={`img-stack ${index === 0 ? "top" : "bottom"}`}>
                <img src={image.src} className="img" alt={image.alt || "Image"} />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentImageSplit;
