import collegesRoutes from "./collegesRoutes";
import examsRoutes from "./examsRoutes";
import factsRoutes from "./factsRoutes";
import jobsRoutes from "./jobsRoutes";
import mainRoutes from "./mainRoutes";

const routesConfig = [
  ...collegesRoutes,
  ...factsRoutes,
  ...examsRoutes,
  ...jobsRoutes,
  ...mainRoutes,
];

export default routesConfig;
