import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Layout from "./components/AppComponents/Layout";
import routesConfig from "./routes/routesConfig";
import { useEffect } from "react";
import { useLoader } from "./context/LoaderContext";
import PageLoader from "./components/AppComponents/PageLoader";

function App() {
  const { setLoading } = useLoader();
  const location = useLocation();

  useEffect(() => {
    setLoading(true);

    // Simulate loading time
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust timeout as needed

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [location.pathname, setLoading]);

  useEffect(() => {
    // Scroll to top after navigation
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <PageLoader />
      <Routes>
        {/* Routes with Layout */}
        <Route element={<Layout />}>
          {routesConfig.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>

        {/* Routes without Layout
        <Route path="/login" element={<LoginPage />} /> */}
      </Routes>
    </>
  );
}

export default App;
