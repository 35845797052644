import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { mainindex } from "../../data/mainindex";
import JobCard from "../../components/Jobs/JobCard";
import styles from "./Jobs.module.css";
import ImageHeader from "../../components/Main/ImageHeader";

const DEFAULT_IMAGE_URL =
  "https://images.unsplash.com/photo-1531538606174-0f90ff5dce83?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwxNHx8fGVufDB8fHx8fA%3D%3D";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      paper: "#1d1d1d",
    },
    text: {
      primary: "#ffffff",
      secondary: "#b0b0b0",
    },
  },
});

const Jobs = () => {
  const { course, field } = useParams();
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const loadJobs = async () => {
      try {
        const jobModule = await import("../../data/Jobs/updatedJobsIndex");
        const jobListings = jobModule?.updatedJobsIndex || [];
        console.log(jobListings);
        setJobs(jobListings);
        const filteredJobs =
          course || field
            ? jobListings.filter((job) => {
                const matchesCourse = course
                  ? job.course.toLowerCase() === course.toLowerCase()
                  : true;
                const matchesField = field
                  ? job.field.toLowerCase() === field.toLowerCase()
                  : true;
                return matchesCourse && matchesField;
              })
            : jobListings; // Show all jobs if no filter is applied

        if (filteredJobs.length === 0) {
          throw new Error("No jobs found for the selected filters.");
        }

        setJobs(filteredJobs);
      } catch (err) {
        setError("Error loading jobs for this category. Please try again later.");
        console.error(err);
      }
    };

    loadJobs();
  }, [course, field]);

  // const categoryData = mainindex.find((cat) => cat.slug === "jobs");
  // console.log(categoryData);

  // if (!categoryData) {
  //   return <h1>Category Not Found</h1>;
  // }

  // if (error) {
  //   return <h1>{error}</h1>;
  // }

  // if (!jobs) {
  //   return <h1>Loading...</h1>;
  // }

  console.log("Jobs : " , jobs);
  return (
    <ThemeProvider theme={darkTheme}>
      <div className={styles["category-page"]}>
        <ImageHeader
          // heading={categoryData.name}
          // text={categoryData.description}
          heading={"Get Top Jobs - Do what you love to do."}
          text={"We are still working on the page."}
          backgroundImage="https://images.unsplash.com/photo-1497032628192-86f99bcd76bc?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />
        <div className={styles["project-container"]}>
          <h2 className={styles["h2updatestatus"]}>We’re busy working on this webpage with care, and the website will be fully ready for you very soon—thank you for your support!</h2>
          <Grid container spacing={3}>
            {jobs.map((job) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={job.slugNumber}>
                <JobCard
                  title={job.role}
                  subheader={`Company: ${job.company} | Location: ${job.location}`}
                  image={DEFAULT_IMAGE_URL}
                  attribution={`Salary: ${job.salary}`}
                  description={`Field: ${job.field}`}
                  jobslug={job.role}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Jobs;
