import ComingSoon from "../pages/Main/ComingSoon";



const examsRoutes = [
  // { path: "/exams", element: <Exams /> },
  // { path: "/exams/:course", element: <CourseExams /> },
  // { path: "/exams/:course/:field", element: <FieldCourseExams /> },
  { path: "/exams", element: <ComingSoon /> },
  { path: "/exams/:course", element: <ComingSoon /> },
  { path: "/exams/:course/:field", element: <ComingSoon /> },
];

export default examsRoutes;
