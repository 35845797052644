import React ,  { createContext , useContext , useState  } from 'react';

const FilterContext = createContext();

const FilterProvider = ({children}) => {
    const [selectedFilters, setSelectedFilters ] = useState({
        course: '',
        place: '',
    });

    const onFilterChange = (newFilters) => {
        setSelectedFilters(newFilters);
    }

    return(
        <FilterContext.Provider value={{selectedFilters, onFilterChange}}>
            {children}
        </FilterContext.Provider>
    )
}

const useFilter = () => {
    return useContext(FilterContext);
}

export { FilterProvider , useFilter };
